.member{
    position: absolute;
    display: flex;
    align-items: center;
    padding: 20px 54px;
    height: 100%;
    z-index: 2;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/member-bg.jpg);
    
    background-size: 100% auto;
    flex-direction: column;
    justify-content: center;
    background-position-y: -700px;
    animation-name: bgChangeY;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}
@keyframes bgChangeY {
    from {
        background-position-y: -700px;
    }
    to {
        background-position-y: 0;
    }
  }

.title{
    color: #FFF;
    text-align: center;
    
    /* Drop shadow */
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    /* H2 */
    font-family: Mortend;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
}
.login{
    opacity: 0;
    margin-top: 70px;
    .tip{
        display: flex;
        justify-content: space-between;
        .login-in{
            color: var(--white, #FFF);
            text-align: center;
            
            /* Drop shadow */
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Mortend;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
        .right{
            display: flex;
            .new{
                color: var(--white, #FFF);
                text-align: center;
                
                /* Drop shadow */
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Blender Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
            }
            .sign{
                color: var(--iris, #9B54EE);
                text-align: center;
                
                /* Drop shadow */
                text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                font-family: Blender Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }
    .email{
        margin-bottom: 24px;
        margin-top: 16px;
        .input{
            display: none;
            width: 404px;
            height: 40px;
            padding-left: 115px;
            background-image: url(../../assets/images/email-input-bg.svg);
            background-repeat: no-repeat;
            background-size: cover;
            outline: none;
            background-color: transparent;
            border: 0;
        }

    }
    .ibutton{
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        &.next{
            width: 404px;
            background-image: url(../../assets/images/next-default.png);
            &:hover{
                background-image: url(../../assets/images/next-hover.png);
            }
        }
    }

}

@media screen and (max-width:768px){
   .member{
    .title{
        font-size: 32px;
    }
    .login{
        .ibutton.next{
            width: 360px;
        }
    }
   }
  }