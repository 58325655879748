body {
    --light: hsl(0, 0%, 100%);
    --background: linear-gradient(to right bottom, hsl(236, 50%, 50%), hsl(195, 50%, 50%));
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', sans-serif;
  
    min-height: 100vh;
    background: linear-gradient(to bottom, hsl(236, 50%, 98%), hsl(236, 50%, 94%));
  }
  
  a,
  a:link {
    font-family: inherit;
    text-decoration: none;
  }
  
  /* modal */
  /* =============================================== */
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  
    display: none;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;
  
    background: hsla(0, 0%, 40%, .6);
  }
  
  /* using :target */
  .modal-container {
    display: flex;
  }
  
  .modal {
    width: 656px;
    height: 600px;
    padding: 0;
    border-radius: 2rem;
  
    color: var(--light);
    //background: var(--background);
    background-color: #fff;
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    position: relative;
  
    overflow: hidden;
  }
  
  .modal__details {
    text-align: center;
  
    margin-bottom: 4rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid hsla(0, 0%, 100%, .4);
  }

  .two{
    display: flex;
    padding: 24px 32px 16px 32px;
    flex-direction: row;
    .mleft{
      margin-left: 10px;
      margin-top: 0;
    }
    .item{
      flex: 1;
      display: flex;
      padding:16px;
      background-color: #F2F2F2;
      border-radius: 8px;
      .item-img{width: 70px;height: 70px;}
      .item-text{
        text-align: left;
        align-content: center;
        padding-left: 10px;

        .item-title{color: #212B36;font-family: Outfit;
          font-size: 16px;
          font-weight: 500;
          line-height: 27px;
          cursor: pointer;
          
          }
        .item-sub-title{font-family: Outfit;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: #333;
          }
      }
    }
  }
  .one{
    margin: 0px 32px;
    display: flex;
    border-radius: 8px;
    flex-direction: row;
    background-color: #F2F2F2;padding: 16px;
    .one-title{
      color: #212B36;
      border-radius: 8px;
      align-content: center;
      flex: 1;
      text-align: left;

      font-family: Outfit;
font-size: 16px;
font-weight: 600;
    }
   
    .ibutton{
      
      background-position: right;
      cursor: pointer;
      &.download-here{
          width: 150px;
          background-image: url(../assets/images/download-here-default.png);
          &:hover{
              background-image: url(../assets/images/download-here-hover.png);
          }
      }
    }
  }

  @media screen and (max-width:768px){
    .two{
      flex-direction: column;
      
      .mleft{
        margin-top: 10px;
        margin-left: 0;
      }
      
    }
    .one{
      flex-direction: column;
      align-items: center;
      .one-title{
        line-height: 2em;
      }
    }
  }

  .down-img{
    width: 100%;
    height: auto;
  }
  .modal__title {
    font-size: 3.2rem;
  }
  
  .modal__description {
    margin-top: 2rem;
  
    font-size: 1.6rem;
    font-style: italic;
  }
  
  .modal__text {
    padding: 0 4rem;
    margin-bottom: 4rem;
  
    font-size: 1.6rem;
    line-height: 2;
  }
  
  .modal__text::before {
    content: '';
  
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-50%, -50%);
  
    width: 18rem;
    height: 18rem;
    border: 1px solid hsla(0, 0%, 100%, .2);
    border-radius: 100rem;
  
    pointer-events: none;
  }
  
  .modal__btn {
    padding: 1rem 1.6rem;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    background: transparent;
    font-size: 1.4rem;
    font-family: inherit;
    letter-spacing: .2rem;
  
    transition: .2s;
    cursor: pointer;
  }
  
  .modal__btn:hover,
  .modal__btn:focus {
    border-color: hsla(0, 0%, 100%, .6);
    transform: translateY(-.2rem);
  }
  
  /* links */
  /* =============================================== */
  .link-1 {
    font-size: 1.8rem;
  
    color: var(--light);
    background: var(--background);
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    border-radius: 100rem;
    padding: 1.4rem 3.2rem;
  
    transition: .2s;
  }
  
  .link-1:hover,
  .link-1:focus {
    transform: translateY(-.2rem);
    box-shadow: 0 0 4.4rem .2rem hsla(236, 50%, 50%, 0.4);
  }
  
  .link-2 {
    width: 40px;
    height: 40px;
    border: 1px solid hsla(0, 0%, 100%, .4);
    border-radius: 100rem;
  
    color: inherit;
    font-size: 24px;
  
    position: absolute;
    top: 2rem;
    left: 2rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.72);
    transition: .2s;
  }
  
  .link-2::before {
    content: '×';
  
    transform: translateY(-.1rem);
  }
  
  .link-2:hover,
  .link-2:focus {
    color: rgba(51, 51, 51, 1);
    border-color: hsla(0, 0%, 100%, .6);
    transform: translateY(-.2rem);
  }
  
  /* Second Version Link */
  /* =============================================== */
  .second-version-link,
  .second-version-link:link {
    color: hsl(236, 50%, 50%);
    padding: .8rem 1.6rem .8rem .2rem;
    border-bottom: 2px solid hsl(236, 50%, 50%);
  
    font-size: 1.4rem;
    font-weight: bold;
  
    position: absolute;
    top: 4rem;
    right: 4rem;
  }
  
  .second-version-link::after {
    content: '\2197';
  
    position: absolute;
    top: 0;
    right: 0;
  
    font-size: .9em;
  }
  
  .abs-site-link {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: hsla(0, 0%, 0%, .6);
    font-size: 1.6rem;
  }