@font-face {font-family:"Mortend Bold";src:url("Mortend_Bold.eot?") format("eot"),url("Mortend_Bold.woff") format("woff"),url("Mortend_Bold.ttf") format("truetype"),url("Mortend_Bold.svg#Mortend-Bold") format("svg");font-weight:normal;font-style:normal;}
@font-face {font-family:"Mortend";src:url("Mortend_Bold.eot?") format("eot"),url("Mortend_Bold.woff") format("woff"),url("Mortend_Bold.ttf") format("truetype"),url("Mortend_Bold.svg#Mortend-Bold") format("svg");font-weight:normal;font-style:normal;}

@font-face {
    font-family: "Poppins";
    src: url("Poppins.ttf") format("truetype");
}
@font-face {
    font-family: "Blender Pro Medium";
    src: url("Blender_Pro_Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Blender Pro";
    src: url("Blender_Pro.otf") format("truetype");
}
