.genesisB{
    display: flex;
    position: absolute;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 54px;
    padding-left: 54px;
    height: 100%;
    z-index: 3;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
    justify-content: center;
        .step{
        
        .t{
            color: var(--blue, #6C98E3);
            text-align: center;
            
            /* H2 */
            font-family: Mortend;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .sub{
            color: var(--darkgrey, #666);
            text-align: center;
            
            /* P1 */
            font-family: Blender Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
        .switch{
            margin-top: 40px;
            display: flex;
            align-items:center;
            justify-content: space-between;
        
            .mid{
                display: flex;
                .item{
                    position: relative;
                    margin: 0 20px;
                    width: 539px;
                    height: 332px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;
                    &:hover{
                        top: 3px;
                        right: 3px;
                        box-shadow: 0px 4px 25px 0px rgba(166, 89, 255, 0.10);
                        transition: all 0.3s linear;
                    }
                    .learn-more{
                        position: absolute;
                        bottom: 20px;
                        left: 20px;
                    }
                    &.a{
                        background-image: url(../../assets/images/nft/other1.png);
                        &:hover{
                            background-image: url(../../assets/images/nft/other1-hover.png);
                        }
                    }
                    &.b{
                        background-image: url(../../assets/images/nft/other2.png);
                    }
                    
                    .img-area{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-left: 15px;
                        padding-right: 15px;
                        .img{
                            width: 100%;
                        }
                    }
                    .name{
                        color: #FFF;

                        /* Card/H2 */
                        font-family: Blender Pro;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        padding-top: 10px;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-bottom: 16px;
                    }
                    .status{
                        color: var(--darkgrey, #666);
                        padding-left: 15px;
                        padding-right: 15px;
                        /* Card/P1 */
                        font-family: Blender Pro;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        padding-bottom: 5px;
                    }
                }
                &.hidden{
                    display: none;
                }
            }
        }
    }

}



@media screen and (max-width:768px){
    .genesisB{
        overflow-y: scroll;
       
        .step{
            .t{
                font-size: 24px;
            }
            .switch{
                flex-direction: column;
                padding-bottom: 200px;
                padding-top: 200px;
                .mid{
                    flex-direction: column;
                    .item{
                        margin: 10px;
                        width: 360px;
                        height: 220px;
                        .learn-more{
                            width: 98px;
                            height: 25px;
                        }
                    }
                }
            }
        }        
    }


}