.storeB{
    position: absolute;
    display: flex;
    padding-bottom: 20px;
    padding-top: 80px;
    padding-right: 54px;
    padding-left: 54px;
    height: 100%;
    z-index: 3;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
  
   
   
    .con{
        margin-top: 31px;
        
        .list{
            margin-top: 51px;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            overflow-y: scroll;
            max-width: 1080px;
            max-height: 100%;
            padding-bottom: 70px;
            &::-webkit-scrollbar {
                width: 4px;
                height: 5px;
                //background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #fff;
            }
            &::-webkit-scrollbar-track {
               
            }
            .item{
                width: 221px;
                height: 311px;
                background-image: url(../../assets/images/nft-bg.png);
                background-repeat: no-repeat;
                background-size: contain;
                margin-bottom: 40px;
                margin-left: 15px;
    margin-right: 15px;
                .img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 15px;
                    .item-img{
                        width: 180px;
                    }
                }
                .name{
                    color: #FFF;

                    /* Card/H2 */
                    font-family: Blender Pro;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    padding-top: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-bottom: 16px;
                    
                }
                .status{
                    color: var(--darkgrey, #666);
                    padding-left: 15px;
                    padding-right: 15px;
                    /* Card/P1 */
                    font-family: Blender Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                }
            }
        }
    }
}