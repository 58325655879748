.loading{
    position: absolute;
    display: flex;
  
    height: 100%;
    width: 100%;
    z-index: 2;
       
    flex-direction: column;
    justify-content: center;
    .loading-img{
        //background-color: #757575;
        width: 100%;
        animation-name:colorChange ;
        animation-duration: 2s;
        animation-timing-function: calc();
        animation-iteration-count: infinite;
    }
}
@media  screen and (max-width:768px){
  .loading{
    display: none;
   
  }
}





@keyframes zoomBigOut {
    from {
      opacity: 1;
      
    }
    
    50% {
      
      transform: scale3d(1.5, 1.5, 1.5);
    }
  
    to {
      transform: scale3d(1.8, 1.8, 1.8);
      opacity: 0;
    }
  }
  
  .zoomBigOut {
    animation-name: zoomBigOut;
  }
  

@keyframes colorChange {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.97;
    }
  }