.main-area{
    background-color: #1A1A1A;
}
.main-content{
    max-width: 1440px;
    margin: 0 auto;
}
.P-home {
    min-height: 100vh;
}
.fullpage-wrapper{
    font-family:"Mortend Bold";
    //font-family: "Blender Pro Medium";
}
.content{
    margin:30px 50px;
    padding: 30px;
    background-color: #fff;
}

.custom-section{
    text-align: center;
    font-size: 4em;
    color: #fff;
}

.actions{
    list-style-type: none;
}
.section{

}
.hidden{display: none;}

.page1{
    background-image: url(../../assets/images/banner-bg1-mask-20.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: flex-end;
    .welcome-to-the-new-world{
        opacity: 1;
        display: flex;
        width: 755px;
        padding-right: 100px;
        flex-direction: column;
        flex-shrink: 0;

        color: #FFF;
        text-align: right;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 53px;
        font-family: Mortend Bold;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
    .s-tip{

        opacity: 0;
        position: absolute;
        right: 124px;
        bottom: 88px;

        display: flex;
        width: 497px;
        flex-direction: column;
        flex-shrink: 0;



        color: #FFF;
        text-align: right;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        
        /* P1 */
        font-size: 14px;
        font-family: Blender Pro;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
    .video{
        opacity: 0;
        margin-top: 32px;
        position: absolute;
        right: 124px;
    }
}
@keyframes bgChange {
    
    from {
        //background-position-x: 1000px;
        
        background-image: url(../../assets/images/banner-bg1-mask-20.jpg);
    }
    to {
        //background-position-x: 0;
        
    }
  }
  .bgChange{
    animation-name: bgChange;
    animation-duration: 3s;
  }
.page2-bg{
    background-image: url(../../assets/images/banner-bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0;
}
.page2{
    //background-image: url(../../assets/images/banner-bg1-mask-20.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    //background-position-x: 1000px;
    position: relative;
    .fp-overflow{
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .unlease{
        flex: 1;
        padding-top: 400px;
        opacity: 0;
        padding-bottom: 100px;
        padding-right: 100px;
        color: var(--white, #FFF);
        text-align: right;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        /* H2 */
        font-size: 36px;
        font-family: Mortend Bold;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        z-index: 1;
        li{
            list-style: none;
        }
    }
    .s-tip{
        flex: 1;
        opacity: 0;
        padding-left: 53px;
        padding-top: 400px;
        padding-bottom: 170px;
        display: flex;
        width: 261px;
        flex-direction: column;
        flex-shrink: 0;
        z-index: 1;

        color: #FFF;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        /* P1 */
        font-size: 14px;
        font-family: Blender Pro;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        li{
            list-style: none;
        }
    }
}
.page3{
    .fp-overflow{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left{
        position: relative;
        width: 477px;
        flex: 1;
        padding-left: 88px;
        .phone-u{
            opacity: 0;
            width: 326px;
            height: 686px;
            flex-shrink: 0;
            background: url(../../assets/images/iphone2-2.png);
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .phone-t{
            opacity: 0;
            position: absolute;
            bottom: -122px;
            left: 239px;
            display: flex;
            width: 326px;
            height: 686px;
            // height: 457px;
            flex-shrink: 0;
            background: url(../../assets/images/iphone2-1.png);
            background-repeat: no-repeat;
            background-size: 100%;
         
            box-shadow: -52px 19px 15px 0px rgba(0, 0, 0, 0.26);
        }
    }
    .right{
        flex: 1;
        .ibutton{
            opacity: 0;
        }
    }
    .download-here-m{
        display: none;
    }
    .title{
        opacity: 0;
        width: 431px;
        color: var(--blue, #6C98E3);

        /* H2 */
        font-size: 36px;
        font-family: Mortend Bold;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        .s-t{
            font-size: 30px;
        }
        .s-t-m{
            display: none;
        }
    }
    .s-tip{
        opacity: 0;
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        width: 430px;
        flex-direction: column;
        flex-shrink: 0;



        color: var(--white, #FFF);

        /* P1 */
        font-size: 14px;
        font-family: Blender Pro;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}
.page4{
    .fp-overflow{
        height: 100vh;
        padding-top: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }
    .title{
        color: var(--blue, #6C98E3);
        text-align: right;
        
        /* H1 */
        font-family: Mortend;
        font-size: 85px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        opacity: 0;
    }
    .con{
        display: flex;
        width: 100%;
        align-items: center;
    }
    .demo{
        display: none;
        margin-top: 50px;
        margin-bottom: 50px;
        height: 40px;
        width: 150px;
        line-height: 42px;
        text-align: center;
        background: url(../../assets/images/button-bg.svg);
        background-repeat: no-repeat;
        background-size: auto 100%;
        color: var(--purple,#9B54EE);
    }
    .left{
        flex: 1 1;
        max-width: 800px;
        .g-list{
            opacity: 0;
            .item{
                display: flex;
                align-items: center;
                margin-bottom: 1.5em;
                .no{
                    width: 36px;
                    height: 37px;
                    background: url(../../assets/images/number-bg.png);
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    color: var(--blue, #6C98E3);
                    text-align: right;
                    font-family: Mortend;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    text-align: center;
                }
                .v{
                    color: #FFF;
                    padding-left: 48px;
                    /* Card/H1 */
                    font-family: Blender Pro;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 36px;
                }
            }
        }
    }
    .right{
        
        .phone-u{
            opacity: 0;
            width: 351px;
            height: 701px;
            flex-shrink: 0;
            //background: url(../../assets/images/iphone2-2.png);
            background-repeat: no-repeat;
            background-size: auto 100%;
            overflow: hidden;
            border: none;
        }
    }
}
.page5{
    .fp-overflow{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }
    .title{
        opacity: 0;
        color: var(--blue, #6C98E3);
        text-align: right;
        
        /* H1 */
        font-family: Mortend;
        font-size: 5.3125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }
    .con{
        display: flex;
        width: 1170px;
        justify-content: space-between;
        padding: 0 120px;
        .item{
            display: block;
            position: relative;
            width: 207px;
            height: 330px;
            
            background-repeat: no-repeat;
            background-size: auto 100%;

            padding-left: 20px;
            padding-top: 215px;
        
            .s-n{//styleName: Card/H1;
                font-family: Blender Pro Medium;
                font-size: 22px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                width: 158px;
                }
            .ss-n{//styleName: Card/P1;
                width: 158px;
                
                font-family: Blender Pro;
                font-size: 11px;
                font-weight: 300;
                line-height: 14px;
                letter-spacing: 0em;
                text-align: left;
                color: #777;

                }
            &:hover{
                top: 3px;
                right: 3px;
                box-shadow: 0px 4px 25px 0px rgba(166, 89, 255, 0.10);
                transition: all 0.3s linear;
            }
            .ibutton{
                margin: 0;
                position: absolute;
                bottom: 10px;
                right: 10px;
                height: 30px;
                background-position: right;
                &.more-info{
                    height: 30px;
                    background-image: url(../../assets/images/nft/more-info-default.svg);
                    &:hover{
                        background-image: url(../../assets/images/nft/more-info-hover.svg);
                    }
                }
            }
            &.a{
                background-image: url(../../assets/images/nft/card1-bg.png);
            }
            &.b{
                background-image: url(../../assets/images/nft/card2-bg.png);
            }
            &.c{
                background-image: url(../../assets/images/nft/card3-bg.png);
            }
            &.d{
                background-image: url(../../assets/images/nft/card4-bg.png);
            }

            
            // &.nft{
            //     opacity: 0;
            //     background-position: left;
            //     background-image: url(../../assets/images/genesis-nft-default.svg);
                
                
            // }
            // &.place{
            //     opacity: 0;
            //     background-position: right;
            //     background-image: url(../../assets/images/place-holder-default-n.svg);
            
            // }
        }
    }
    .nft-store{
        opacity: 0;
        margin-top: 50px;
     
    }
    
}

@keyframes rotateInX {
    from {
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 1;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 1);
      transform: translate3d(0, 0, 1);
      opacity: 1;
    }
  }
.rotateInX{
    animation-name: rotateInX;
    transition-duration: 2s;
   
}
@keyframes fadeInDelay {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
.fadeInDelay{
    animation-name: fadeInDelay;
    animation-delay: 1.5s;
   
}
@keyframes router360 {
    from {
      transform: rotate(0deg);
      opacity: 1;
    }
  
    to {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
.router360{
    animation-name: router360;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}
.page6{
    .fp-overflow{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: column;
    }
    .title{
        opacity: 0;
        color: var(--blue, #6C98E3);
        text-align: right;
        
        /* H1 */
        font-family: Mortend;
        font-size: 5.3125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        z-index: 2;
        
    }
    .token{
        position: absolute;
        height: 100%;
        opacity: 0;
    }
    .token-circle{
        position: absolute;
        height: 100%;
        opacity: 0;
        &.show{
            opacity: 1;
        }
    }

    .con{
        margin-right: auto;
        padding-left: 54px;
    }
    .ibutton{opacity: 0;}
   .tip{
    opacity: 0;
    margin-top: 32px;
    .item{
        list-style-type: none;
        color: var(--white, #FFF);

        /* P1 */
        font-family: Blender Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
   }
    
}

@keyframes bgChangeY {
    from {
        background-position-y: 1000px;
    }
    to {
        background-position-y: 0;
    }
  }
  .bgChangeY{
    animation-name: bgChangeY;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
  }

  @keyframes fadeBigIn {
    from {
        opacity: 0;
        transform: scale(1.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
  }
  .fadeBigIn{
    animation-name: fadeBigIn;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
  }
.page7{
    background-image: url(../../assets/images/banner-bg7-mask-20.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 1000px;
    position: relative;
    .fp-overflow{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }
    .roadmap{
        opacity: 0;
        margin-bottom: 46px;
        padding-top: 14%;
    }

}
.page8{
    .fp-overflow{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
    }
    .title{
        opacity: 0;
        color: var(--blue, #6C98E3);
        text-align: right;
        
        /* H1 */
        font-family: Mortend;
        font-size: 5.3125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        z-index: 2;
        text-transform: uppercase;
    }
    .con{
        opacity: 0;
        padding-bottom: 70px;
        &::-webkit-scrollbar {
            //width: 8px;
            height: 5px;
            //background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }
        &::-webkit-scrollbar-track {
           
        }
        display: flex;
        width: 80%;
        justify-content: space-between;
        overflow-y: hidden;
        overflow-x: scroll;
        .item{
            width: 190px;
            height: 330px;
            text-align: center;
            // height:402px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            flex-basis: 190px;
            flex-shrink: 0;
            margin: 0 24px;
            .name{
                margin-top: 126px;
                color: #FFF;
                font-family: Mortend;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 24.5px;
            }
            .sub{color: var(--darkgrey, #666);

                /* P1 */
                font-family: Blender Pro;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;}
            .intro{color: #FFF;
                text-align: left;
                
                /* P1 */
                font-family: Blender Pro;
                
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                padding: 14px;
                margin-top: 8px;
                height: 140px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 3px;
                    height: 5px;
                    //background-color: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                }
                &::-webkit-scrollbar-track {
                   
                }
            }
            &.a{
                background-image: url(../../assets/images/nick.png); 
            }
            &.b{
                background-image: url(../../assets/images/aney.png); 
            }
            &.c{
                background-image: url(../../assets/images/howard.png); 
            }
            &.d{
                background-image: url(../../assets/images/zack.png); 
            }
            &.e{
                background-image: url(../../assets/images/george.png); 
            }
            &.f{
                background-image: url(../../assets/images/joyhoo.png); 
            }
            &.g{
                background-image: url(../../assets/images/tim.png); 
            }
            &.h{
                background-image: url(../../assets/images/diana.png); 
            }






        }
    }
    
}
.page9{
    .fp-overflow{
        padding-top: 172px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-flow: column;
    }
    .title{
        opacity: 0;
        margin-top: 150px;
  
        color: var(--blue, #6C98E3);
        text-align: right;
        
        /* H1 */
        font-family: Mortend;
        font-size: 5.3125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        z-index: 2;
        text-transform: uppercase;
    }
    .con{
        opacity: 0;
        display: flex;
        
        flex-direction: column;
        width: 1150px;
        .arrayA{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 72px;
            .partner{
                height: 70px;
              
            }
        }
        .arrayB{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 150px;
            .partner{
                height: 66px;
            }
        }
    

    }
    .pfooter{
        width: 100%;
        padding: 0 54px;
        background: url(../../assets/images/footer.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding-top:30px;
    }
    .fmenu{
        opacity: 0;
        display: flex;
        margin-bottom: 36px;
        margin-top: 20px;
        .left{
            flex:1;
        }
        .right{
            position: relative;
            display: flex;
            width: 367px;
            flex-flow: wrap;
            justify-content: space-between;
            .top{
            
                position: absolute;
                top: 20px;
                right: 0;
                .totop{
                    width: 60px;
                    height: 60px;
                   

                    background-image: url(../../assets/images/top-default.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    &:hover{
                        background-image: url(../../assets/images/top-hover.png);
                    }
                }
            }
            .c-info{
                display: flex;
                .sub{

                    &.contact{
                        .item{
                            cursor: default;
                            &:hover{
                                color: var(--white);
                            }
                        }
                        .email{
                            color: #fff;
                            font-size: 10px;
                            font-family: 'Poppins';
                        }
                    }
                }

            .item{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                line-height: 2.8em;

                color: var(--white, #FFF);
                font-family: Mortend;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                width: 164px;
                cursor: pointer;
                &:hover{
                    color: var(--purple);
                }
            }                
            }

        }

        .ibutton{
            margin-bottom: 24px;
        }
    }
    .copy-area{
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        justify-content: space-between;
        .socials{
            display: flex;
            align-items: center;
            .icon{
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 40px;
                cursor: pointer;
            }
            .twitter{
                background-image: url(../../assets/icons/twitter.png);
                &:hover{
                    background-image: url(../../assets/icons/twitter-hover.png);
                }
            }
            .instagram{
                background-image: url(../../assets/icons/instagram.png);
                &:hover{
                    background-image: url(../../assets/icons/instagram-hover.png);
                }
            }
            .telegram{
                background-image: url(../../assets/icons/Telegram.png);
                &:hover{
                    background-image: url(../../assets/icons/Telegram-hover.png);
                }
            }            
            .game{
                background-image: url(../../assets/icons/game.png);
                &:hover{
                    background-image: url(../../assets/icons/game-hover.png);
                }
            }  
        }
        .copy{
            text-align: center;

            color: #FFF;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }        
    }



}

.ibutton{
    cursor: pointer;
    &.video{
        width: 150px;
        background-image: url(../../assets/images/viedo-default.png);
        &:hover{
            background-image: url(../../assets/images/viedo-hover.png);
        }
    }
    &.download-here{
        width: 150px;
        background-image: url(../../assets/images/download-here-default.png);
        &:hover{
            background-image: url(../../assets/images/download-here-hover.png);
        }
    }
    &.download-here-m{
        width: 150px;
        background-image: url(../../assets/images/download-here-default.png);
    }
    &.nft-store{
        width: 150px;
        background-image: url(../../assets/images/nft-store-default.png);
        &:hover{
            background-image: url(../../assets/images/nft-store-hover.svg);
        }
    }
    &.more-info{
        width: 158px;
        background-image: url(../../assets/images/more-info-default.png);
        &:hover{
            background-image: url(../../assets/images/more-info-hover.png);
        }
    }
    &.learn-more{
        width: 158px;
        background-image: url(../../assets/images/nft/Learn-more-default.svg);
        &:hover{
            background-image: url(../../assets/images/nft/Learn-more-hover.svg);
        }
    }
    &.more-info-disabled{
        width: 158px;
        background-image: url(../../assets/images/more-info-disabled.png);
       
    }     
    &.download{
        width: 175px;
        background-image: url(../../assets/images/download-default.png);
        &:hover{
            background-image: url(../../assets/images/download-hover.png);
        }
    }
    &.playnow{
        width: 175px;
        cursor: default;
        background-image: url(../../assets/images/nav-play-now-default.png);
        // &:hover{
        //     background-image: url(../../assets/images/nav-play-now-hover.png);
        // }
    }
    &.ios{
        width: 175px;
        cursor: default;
        background-image: url(../../assets/images/ios-default.png);
        // &:hover{
        //     background-image: url(../../assets/images/ios-hover.png);
        // }
    }
    &.googleplay{
        width: 175px;
        cursor: default;
        background-image: url(../../assets/images/googleplay-default.png);
        // &:hover{
        //     background-image: url(../../assets/images/googleplay-hover.svg);
        // }
    }

    
}


.fp-watermark{
    display: none;
}
.fp-section{
    //padding: 20px;
}

#fp-nav{
    z-index: 2 !important;
}


@media screen and (max-width:768px){
    .page1{
        background-image: url(../../assets/images/mobile/page1-bg.png);
        .fp-overflow{
            padding-bottom: 10%;
        }
        .welcome-to-the-new-world{
            font-size: 36px;
           
            padding-right: 16px;
            width: auto;
        }
        .video{
            right: 16px;
        }
        .s-tip{
            width: auto;
        }

    }
    .page2{
        .fp-overflow{
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .page2-bg{
            background-image: url(../../assets/images/mobile/page2-bg.svg);
        }
        .s-tip{
            flex: none;
            padding-left: 16px;
            padding-right: 16px;
            width: auto;
            padding-bottom: 96px;
            padding-top: 0;
        }
        .unlease{
            font-size: 24px;
            line-height: 31px;
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 0;
            padding-bottom: 14px;
            flex: none;
        }
    }
    .page4{
        .fp-overflow{
            padding-top: 0;
        }
        .title{
            font-size: 32px;
        }
        .left{
            .g-list{
                padding-inline-start: 20px;
                .item{
                    margin-bottom: 1em;
                    .no{
                        width: 20px;
                        height: 20px;
                        font-size: 11px;
                        line-height: 26px;
                    }
                    .v{
                        padding-left: 24px;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
        .demo{
            margin-top: 0;
            display: block;
            background-image: url(../../assets/images/mobile/game-bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            width: 305px;
            height: 144px;
        }
        .right{
            display: none;
        }
    }
    .page3{
        .fp-overflow{
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
        .title{
            width: auto;
            font-size: 28px;
        
            .t{
                width: 250px;
                text-align: left;
            }
            .s-t{
                display: none;
                font-size: 24px;
            }
            .s-t-m{
                display: block;
                margin-top: 10px;
                background-image: url(../../assets/images/mobile/chat-logo.svg);
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                width: 135px;
                height: 25px;
            }
        }
        .download-here-m{
            display: block;
            opacity: 0;
            margin-top: 30px;
        }
        .left{
            display: flex;
            width: auto;
            
            padding-right: 40px;
            flex: none;
            .phone-u{
                //background-image: url(../../assets/images/mobile/Welcome.png);
                width: 160px;
                height: 347px;
            }
            .phone-t{
                //background-image: url(../../assets/images/mobile/Moment.png);
                right: 0;
                left: -33px;
                width: 160px;
                height: 347px;
                bottom: -34px;
                position: relative;
            }
        }
        .right{
            flex: none;
            margin-bottom: 30px;
            .ibutton{
                display: none;
            }
            .s-tip{
                display: none;
            }
            
        }
    }
    .page5{
        .title{
            font-size: 28px;
            text-align: center;
        }
        .con{
            padding: 20px;
            width: 100%;
            overflow-x: scroll;
            .item{
                background-size: cover;
                width: 100%;
                
                margin-bottom: 10px;
                margin-left: 10px;
                margin-right: 10px;
                .s-n{
                    font-size: 20px;
                    
                    line-height: 26px;
                }
                .ss-n{
                    font-size: 12px;
                }
                .ibutton{
                    width: 98px;
                    
                    bottom: 10px;
                }
            }
        }
        .nft-store{
            width: 125px;
            margin-top: 10px;
        }
    } 
    .page6{
        .title{
            font-size: 32px;
            margin-bottom: 70px;
        }
        .token{
            width: 100%;
            margin-left: -74px;
        }
        .token-circle{
            width: 100%;
            margin-left: -74px;
        }
    }
    .page7{
        background-image: url(../../assets/images/mobile/page7-bg.png);
        .fp-overflow{
            padding-bottom: 128px;
            justify-content: flex-end;
        }
        .roadmap{
            width: 100%;
            padding: 20px;
        }
    }
    .page8{
        .title{
            text-align: center;
            font-size: 32px;
            padding: 0 24px;
        }
    }
    .page9{
        .fp-overflow{
            justify-content: flex-start;
        }
        .title{
         
            text-align: center;
            font-size: 32px;
            margin-top: 0;
            margin-bottom: 32px;
        }
        .con{
            .arrayA{
                flex-direction: column;
                margin-bottom: 0;
                .partner{
                    height: 32px;
                    margin-bottom: 24px;
                }
            }
            .arrayB{
                flex-direction: column;
                margin-bottom:20px;
                .partner{
                    height: 32px;
                    margin-bottom: 24px;
                }
            }
        }
        .pfooter{
            display: none;
        }
        .pfooter-m{
            position: absolute;
            display: flex;
            align-items: center;
            padding: 20px 54px;
            bottom: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            .icon{
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 40px;
                cursor: pointer;
            }
            .twitter{
                background-image: url(../../assets/icons/twitter.png);
                &:hover{
                    background-image: url(../../assets/icons/twitter-hover.png);
                }
            }
            .telegram{
                background-image: url(../../assets/icons/Telegram.png);
                &:hover{
                    background-image: url(../../assets/icons/Telegram-hover.png);
                }
            }    
            .game{
                background-image: url(../../assets/icons/game.png);
                &:hover{
                    background-image: url(../../assets/icons/game-hover.png);
                }
            }   
        }
        .fmenu{
            flex-direction: column;
        }
        .copy-area{
            flex-direction: column;
            .socials{
                margin-bottom:20px;
            }
        }
    }
}