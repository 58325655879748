@import '../src/assets/fonts/fonts.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  /* COLOR PAIETTE */
  --black:#1a1a1a;
  --blue:#6c98e3;
  --purple:#9b54ee;
  --white:#fff;
  --gray:#666;
  --purpleo:rgba(155,84,238,0.2);
  --purple20:#342644;
}


