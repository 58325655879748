.genesis{
    display: flex;
    position: absolute;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 54px;
    padding-left: 54px;
    height: 100%;
    z-index: 3;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
    justify-content: center;
    .step{
        
        .t{
            color: var(--blue, #6C98E3);
            text-align: center;
            
            /* H2 */
            font-family: Mortend;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .sub{
            color: var(--darkgrey, #666);
            text-align: center;
            
            /* P1 */
            font-family: Blender Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
        .switch{
            margin-top: 40px;
            display: flex;
            align-items:center;
            justify-content: space-between;
            .prev{
                width: 29.46px;
                height: 51.8px;
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
                background-image: url(../../assets/images/s-previous-default.png);
                &:hover{
                    background-image: url(../../assets/images/s-previous-hover.png);
                }
                margin-right: 120px;
            }
            .next{
                width: 29.46px;
                height: 51.8px;
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
                background-image: url(../../assets/images/s-next-efault.png);
                &:hover{
                    background-image: url(../../assets/images/s-next-hover.png);
                }
                margin-left: 120px;

            }
        
            .mid{
                display: flex;
                .item{
                    margin: 0 20px;
                    width: 280px;
                    height: 394px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;
                    background-image: url(../../assets/images/fire-default.svg);
                    .img-area{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-left: 15px;
                        padding-right: 15px;
                        .img{
                            width: 100%;
                        }
                    }
                    .name{
                        color: #FFF;

                        /* Card/H2 */
                        font-family: Blender Pro;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        padding-top: 10px;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-bottom: 16px;
                    }
                    .status{
                        color: var(--darkgrey, #666);
                        padding-left: 15px;
                        padding-right: 15px;
                        /* Card/P1 */
                        font-family: Blender Pro;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                    }
                }
                &.hidden{
                    display: none;
                }
            }
        }
    }    
}


.step1{

}
.step2{
   .chart{
    margin-top: 80px;
    height: 396px;
   } 
}
.step3{
    .mid{
        display: flex;
        justify-content: center;
        margin-top: 48px;
        .item{
            margin: 0 20px;
            width: 280px;
            height: 394px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:768px){
    .genesis{
        overflow-y: scroll;
        padding-top: 830px;
        padding-bottom: 50px;
        .step{
            .t{
                font-size: 24px;
            }
            .switch{
                flex-direction: column;
                padding-bottom: 200px;
                .prev{
                    transform: rotate(90deg);
                    margin: 0 auto;
                }
                .next{
                    transform: rotate(90deg);
                    margin: 0 auto;
                }
                .mid{
                    flex-direction: column;
                    .item{
                        margin: 20px;
                    }
                }
            }
        }        
    }

    .step2{
        padding-bottom: 150px;
        .chart{
            margin-top: 0;
        }
    }
    .step3{
        .mid{
            flex-direction: column;
            .item{
                margin: 20px;
            }
        }
    }
}