.back-home{
    position: absolute;
    width: 29.46px;
    height: 51.8px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-image: url(../../assets/images/s-previous-default.png);
    &:hover{
        background-image: url(../../assets/images/s-previous-hover.png);
    }
    left: 0;
    top: 50%;
}
.tokenomics{
    position: absolute;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 54px;
    padding-left: 54px;
    height: 100%;
    z-index: 3;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
    .step1{
        display: flex;
        align-items: center;
        
        justify-content: space-between;
        width: 100%;
    }
    .others{
        width: 414px;
        height: 438px;
        background-image: url(../../assets/images/others-bg.svg);
        background-repeat: no-repeat;
        background-size: contain;
        padding: 60px 50px;
        .title{color: var(--blue, #6C98E3);

            /* H2 */
            font-family: Mortend;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            padding-bottom: 20px;
        }
        .ilist{
            display: flex;
            flex-direction: column;
            .item{
                display: flex;
                color: #FFF;

                /* Card/H2 */
                font-family: Blender Pro;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 1.8em;
                .v{width: 112px;}
                .n{

                }
            }
        }
    }
    .chart{
        .chart-img{
            width: 100%;
        }
    }
}
.step2{
    display: flex;
   
    width: 100%;
    flex-direction: column;
    .top{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .helicopter{width: 763px;
            height: 506px;}
        .certik-logo{width: 104.83px;
            height: 100.34px;}
    }
    .contarct{
        margin-top: 40px;
        .st{color: var(--blue, #6C98E3);

            /* H2 */
            font-family: Mortend;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;}
        .addr{color: #FFF;
            margin-top: 8px;
            margin-bottom: 8px;
            /* Card/H2 */
            font-family: Blender Pro;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;}
        .by{color: var(--darkgrey, #666);

            /* Card/P1 */
            font-family: Blender Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;}
    }
}
@media  screen and (max-width:768px){
    .tokenomics{
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 0;
        .step1{
            flex-direction: column-reverse;
            .chart{
                margin-bottom: 30px;
            }
        }
        .others{
            padding: 20px;
            width: 100%;
            .ilist{
                .item{
                    font-size: 20px;
                }
            }
        }
        .step2{
            margin-bottom: 0;
            .top{
                flex-direction: column-reverse;
                .helicopter{
                    width: 100%;
                    height: auto;
                    margin-bottom: 80px;
                }
            }
            .contarct{
                .addr{
                    word-break: break-all;
                }
            }
        }
    }
}