.store{
    position: absolute;
    display: flex;
    padding-bottom: 20px;
    padding-top: 80px;
    padding-right: 54px;
    padding-left: 54px;
    height: 100%;
    z-index: 3;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
  
   
    .side{
        width: 245px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 138px;
        .item{
            color: #FFF;
            font-family: Mortend;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 16px;
            cursor: pointer;
            &:hover,.active{
                color: var(--purple);
            }
           
        }
    }
    .con{
        margin-top: 31px;
        .top{
            display: flex;
            justify-content: space-between;
            .search{
                width: 670px;
                height: 40px;
                background-image: url(../../assets/images/search-bg.svg);
                background-repeat: no-repeat;
                background-size: contain;
                .search-input{
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    line-height: 40px;
                    margin-left: 50px;
                    color: #fff;
                    font-family: Blender Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    width: 600px;
                    outline: none;
                }
            }
            .option{
                display: flex;
                width: 220px;
                height: 40px;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(../../assets/images/option-bg.svg);
                align-items: center;
                position: relative;
                .value{
                    color: #FFF;
                    font-family: Blender Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    width: 170px;
                    text-align: center;
                }
                .img-area{
                    width: 50px;
                    text-align: center;
                    .up-down{
                        width: 8.73px;
                        height: 4.39px;
                    }                    
                }
                &:hover{
                    
                }
            }
            .vlist{
                display: none;
                position: absolute;
                top:40px;
                background-color: #292929;
                margin-left: 10px;
                width: 210px;
                .item{
                    line-height: 3em;
                    padding-left: 30px;
                    color: var(--darkgrey, #666);

                    /* P1 */
                    font-family: Blender Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    cursor: pointer;
                }
                &.active{
                    display: block;
                }
            }
        }
        .list{
            margin-top: 51px;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
            overflow-y: scroll;
            max-width: 1004px;
            max-height: 100%;
            padding-bottom: 70px;
            &::-webkit-scrollbar {
                width: 4px;
                height: 5px;
                //background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #fff;
            }
            &::-webkit-scrollbar-track {
               
            }
            .item{
                width: 221px;
                height: 311px;
                background-image: url(../../assets/images/nft-bg.png);
                background-repeat: no-repeat;
                background-size: contain;
                margin-bottom: 40px;
                .img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 15px;
                    
                }
                .name{
                    color: #FFF;

                    /* Card/H2 */
                    font-family: Blender Pro;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    padding-top: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-bottom: 16px;
                }
                .status{
                    color: var(--darkgrey, #666);
                    padding-left: 15px;
                    padding-right: 15px;
                    /* Card/P1 */
                    font-family: Blender Pro;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                }
            }
        }
    }
}

@media screen and (max-width:768px){
    .store{
        padding-left: 16px;
        padding-right: 16px;
        .side{
            display: none;
        }
        .con{
            margin-top: 0;
            width: 100%;
            .top{
                flex-direction: column;
                .search{
                    width: 100%;
                    .search-input{
                        width: 100%;
                        line-height: 18px;
                        margin-left: 25px;
                    }
                }
            }
            .list{
                flex-direction: column;
                .item{
                    margin-right: 10px;
                }
            }
        }
    }
}