.header{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 20px 54px;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    font-family: "Mortend Bold";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 100%);

}
.sider{
    background-image: url(../assets/images/pagination/pagin-bg.svg);
    background-repeat: no-repeat;
    background-position: 20px;
    height: 600px;
    width: 86px;
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 1;
    .current{
            margin-top: 29.5px;
            transition: all 0.5s linear;
            display: flex;
            align-items: center;
        .arrow{}
        .name{
            width: auto;
            height: 48px;
            /* Drop shadow */
            
            .text{
                color: #FFF;
                text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                font-family: Mortend;
                font-size: 6px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;                
            }
        
        }
    }
}
.footer{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 20px 54px;
    bottom: 0;
    left: 0;
    z-index: 10;
    //width: 100%;
    width: auto;
    .icon{
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 40px;
        cursor: pointer;
    }
    .twitter{
        background-image: url(../assets/icons/twitter.png);
        &:hover{
            background-image: url(../assets/icons/twitter-hover.png);
        }
    }
    .telegram{
        background-image: url(../assets/icons/Telegram.png);
        &:hover{
            background-image: url(../assets/icons/Telegram-hover.png);
        }
    }    
    .instagram{
        background-image: url(../assets/icons/instagram.png);
        &:hover{
            background-image: url(../assets/icons/instagram-hover.png);
        }
    }
    .game{
        background-image: url(../assets/icons/game.png);
        &:hover{
            background-image: url(../assets/icons/game-hover.png);
        }
    }   
}
.home-menu{

    position: absolute;
    display: flex;
    align-items: center;
    padding: 20px 54px;
    height: 100%;
    z-index: 9;
    width: 100%;
    font-family: "Mortend Bold";
    background-color: #1a1a1a;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    .menu-m{
        display: none;
    }
    .item{
        
        color: var(--white, #FFF);
        padding: 0 24px;
        /* H2 */
        font-family: Mortend;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 2em;
        cursor: pointer;
        &:hover{
            color: var(--purple);
            background: url(../assets/icons/left-bracket.svg) no-repeat left center,
            url(../assets/icons/right-bracket.svg) no-repeat right center,
            ;
        }
    }
}
.logo-area{
    flex: 1;
    text-align: center;
}
.logo{
    height: 30px;
    cursor: pointer;
}
.ibutton{
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
}
.download-game{
    width: 225px;
    cursor: default;
    background-image: url(../assets/images/nav-download-game-Default.png);
    &:hover{
        background-image: url(../assets/images/nav-download-game-hover.png);
    }
}
.membership{
    margin-right: 60px;
    width: 176px;
    background-image: url(../assets/images/nav-membership-Default.png);
    &:hover{
        background-image: url(../assets/images/nav-membership-hover.png);
    }
}
.top-menu{
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../assets/images/menu-default.svg);
    &.active{
        background-image: url(../assets/images/menu-close.svg);
        &:hover{
            background-image: url(../assets/images/menu-close.svg);
        }
    }
    &:hover{
        background-image: url(../assets/images/menu-hover.svg);
    }
}

@media screen and (max-width:768px){
    .header{
        padding: 20px 15px;
        .logo-area{
            margin: 0 20px;
            .logo{
                height: 20px;
            }
        }
        .ibutton{
            height: 27px;
            display: none;
        }
        .membership{
            margin-right: 28px;
        }
        .top-menu{
            height: 20px;
        }
    }
    .sider{
        display: none;
    }
    .home-menu{
        padding: 20px 15px;
        .menu-m{
            display: block;
            margin-top: 10px;
            .download-game{
                margin-bottom: 16px;
            }
        }
        .item{
            font-size: 24px;
        }
    }
    
}